import DOMPurify from "dompurify";

import { useProductDetails } from "../productContext/ProductProvider";
import styles from "./keyFeatures.module.css";
import productStyles from "../productDetails.module.css";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

function KeyFeatures() {
	const { t, i18n } = useTranslation();
	const {
		productDetails: { keyFeature },
		lng,
	} = useProductDetails();

	const { featuresArray } = useMemo(() => getKeyFeaturesData(keyFeature, lng), [keyFeature, lng]);

	// const layoutCol=useMasonryLayout(featuresArray,()=>{

	// })

	return (
		<section className={`${productStyles.section} ${styles.keyFeatures}`}>
			<div className={productStyles.title}>{i18n.resolvedLanguage === "fr-CA" ? t("Key") + "\n" + t("Features") : "Key Feature"}</div>
			<div className={styles.keyFeatures__content}>
				{[0, 1].map((v) => {
					return (
						<div className={styles.keyFeatures__content_flexcol} key={v}>
							{featuresArray &&
								featuresArray.map((feature, index) => {
									const santizedHeadline = DOMPurify.sanitize(feature.headline);
									const hasImage = feature?.featureImage;
									return index % 2 == v ? (
										hasImage ? (
											<ImageFeature feature={feature} key={santizedHeadline} />
										) : (
											<SimpleFeature feature={feature} key={santizedHeadline} background />
										)
									) : null;
								})}
						</div>
					);
				})}
			</div>
		</section>
	);
}

KeyFeatures.shouldRender = ({ productDetails: { keyFeature }, lng }) => {
	return getKeyFeaturesData(keyFeature, lng).isVisible;
};

function getKeyFeaturesData(keyFeature, lng) {
	if (!keyFeature) return { featuresArray: null, isVisible: false };

	const featuresArray = keyFeature[lng] ?? keyFeature["en_US"];
	return {
		featuresArray,
		isVisible: Boolean(featuresArray?.length),
	};
}

export default KeyFeatures;

function SimpleFeature({ feature, background }) {
	const santizedHeadline = DOMPurify.sanitize(feature.headline);
	const santizedDescription = DOMPurify.sanitize(feature.description);
	const [copied, setCopied] = useState("");
	useEffect(() => {
		let timerId;
		if (copied !== "") {
			timerId = setTimeout(() => {
				setCopied("");
			}, 2000);
		}

		return () => {
			if (timerId) {
				clearTimeout(timerId);
			}
		};
	}, [copied]);

	return (
		<div className={`${styles.keyFeatures__feature} ${background ? styles.white : ""}`}>
			<div
				className={styles.keyFeatures__feature__headline}
				dangerouslySetInnerHTML={{
					__html: santizedHeadline,
				}}
				onClick={() => {
					setCopied("Headline");
					navigator.clipboard.writeText(santizedHeadline);
				}}
			/>
			<div
				className={styles.keyFeatures__feature__description}
				dangerouslySetInnerHTML={{
					__html: santizedDescription,
				}}
				onClick={() => {
					setCopied("Description");
					navigator.clipboard.writeText(santizedDescription);
				}}
			/>
			<CopyToast text={copied} />
		</div>
	);
}

function ImageFeature({ feature }) {
	const { assetData } = useProductDetails();
	const featureImageId = feature?.featureImage;
	const featureImageUrl = assetData[featureImageId]?.largeAssetPath;
	const [imageFailed, setImageFailed] = useState(false);
	return (
		<>
			{imageFailed ? (
				<SimpleFeature feature={feature} />
			) : (
				<div className={styles.keyFeatures__feature_image}>
					<img
						src={featureImageUrl}
						onError={(e) => {
							setImageFailed(true);
						}}
					/>
					<div className={styles.keyFeatures__overlay}>
						<SimpleFeature feature={feature} />
					</div>
				</div>
			)}
		</>
	);
}

function CopyToast({ text }) {
	const { t } = useTranslation();
	if (text === "") return null;
	return (
		<div className={styles.keyFeatures__copyToast}>
			{text} {t("Copied!")}
		</div>
	);
}
